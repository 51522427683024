
<template>
  <div style="background-color: #F8F8F8">
    <div class="nav_menu info" style="width: 100%">
      <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;margin-left: 84px">
        <img
            class="label_1 "
            referrerpolicy="no-referrer"
            src="../assets/img/wowpic.png"
        />
        <span class="text_1 footerwowtext">哇呜说</span>
      </div>
      <div style="margin-right: -30px;margin-left: 142px" class="menu_item sy" :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event)">
        <div class="home" style="width: 38px;">首页</div>
        <div class="web_home" style="width: 38px;">首页</div>
      </div>

      <div style="margin-left: 132px" id="xx"  class="menu_item"  :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }" >
        <div class="home">产品介绍</div>
        <div class="web_home"  @click.stop="page(2,$event)">产品介绍</div>
      </div>

      <div style="margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }" @click="page(3,$event)" @mouseover="" @animationend="">
        <div class="home">相关资讯</div>
        <div class="web_home">相关资讯</div>
      </div>

      <div style="margin-right: 30px;margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event)" @mouseover="" @animationend="">
        <div class="home">关于我们</div>
        <div class="web_home">关于我们</div>
      </div>
    </div>
    <div class="topbanner">
        <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
          <div class="swiper-wrapper" style="z-index: 0">
            <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in hardlistbanner" :key="index">
              <img alt="" :src="item.img" style="width: 100%;">
            </div>
          </div>
        </div>
        <!--        <span class="newstext1">关于哇呜说</span>-->
        <!--        <span class="newstext2">实时关注，实时更新，第一时间掌握哇呜推荐与新闻资讯 更快了解新闻动态 </span>-->

      <p class="app">
        硬件介绍
      </p>
      <p class="app_int">
        这里展示对硬件的介绍
      </p>
<!--      <img src="../assets/img/xin.png" style="width: 70px;height: 70px;left: 907px;top: 59px" alt="">-->
<!--      <img src="../assets/img/yinxiang.png" style="height: 283px;right: 636px;top: 30px" alt="">-->
<!--      <img src="../assets/img/note.png" style="width: 58px;height: 58px;right: 537px;bottom: 20px" alt="">-->
    </div>
    <div style="height: 60px;margin-top: 19px" class="nav_top">
      <div style="width: 1200px;height: 60px;margin: 0 auto">
        <span>您的位置:</span>&nbsp;&nbsp;
        <span @click="$router.push('/introduction')" style="cursor: pointer">产品介绍></span>&nbsp;&nbsp;
        <span style="color: #F8AE01">硬件介绍</span>&nbsp;&nbsp;
      </div>

    </div>
    <div style="width:1000px;margin: 100px auto;display: flex;justify-content: space-between;">
          <div  style="width: 475px" v-for="(item,index) in introDataINfo1" :key="index">
            <div class="image-container" @mouseenter="hoverStates[index] = true" @mouseleave="hoverStates[index] = false">
              <img v-for="(item1,index1) in item.imgs" @click="godetails(item.imgs[0])" :src="item1.img" alt="" style="width: 475px;height: 640px">
              <div   v-if="hoverStates[index]" :class="['overlay', { 'show': hoverStates[index] }]">
                <div class="overlay-text">查看详情</div>
              </div>
            </div>
            <p style="width: 60px;
height: 28px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 20px;
color: #333333;
line-height: 28px;
text-align: left;
font-style: normal;margin-top: 20px">{{ item.title }}</p>
            <p style="width: 160px;
height: 22px;
font-family: PingFangSC, PingFang SC;
font-weight: 400;
font-size: 16px;
color: #333333;
line-height: 22px;
text-align: left;
font-style: normal;">{{ item.subtitle }}</p>
          </div>
<!--      <div style="width: 475px;    text-align: -webkit-right;">-->
<!--        <p style="width: 60px;-->
<!--height: 28px;-->
<!--font-family: PingFangSC, PingFang SC;-->
<!--font-weight: 400;-->
<!--font-size: 20px;-->
<!--color: #333333;-->
<!--line-height: 28px;-->
<!--font-style: normal;margin-top: 20px">话筒</p>-->
<!--        <p style="width: 160px;-->
<!--height: 22px;-->
<!--font-family: PingFangSC, PingFang SC;-->
<!--font-weight: 400;-->
<!--font-size: 16px;-->
<!--color: #333333;-->
<!--line-height: 22px;-->
<!--font-style: normal;margin-bottom: 20px">聆听宝贝的声音</p>-->
<!--        <div class="image-container" @mouseenter="hover = true" @mouseleave="hover = false">-->
<!--          <img src="../assets/img/ht.png" alt="" style="width: 475px;height: 640px">-->
<!--          <div @click="godetails()" v-if="hover" :class="['overlay', { 'show': hover }]">-->
<!--            <div class="overlay-text">查看详情</div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
    </div>
    <div style="height: 1px;width: 100%"></div>
  </div>
</template>
<script>
import {gettab} from "@/api/user";
import Swiper from "swiper";

export default {
  name: "int_app",
  data() {
    return {
      currentPage: 2,
      clickedItems: {},
      isRotated: false,
      articleContent:``,
      hover2: false,
      hover: false,
      introDataINfo1:'',
      introData_banner:'',
      hoverStates: [],
      hardlistbanner:'',
      yid:'',

    }
  },
  methods:{
    page(v,event) {
      if (!this.clickedItems[v]) {
        this.$nextTick(() => {

        });
      }
      // document.getElementById('xx').style.fontWeight = 'unset !important';
      // this.navanimate(1,3)
      console.log(event);
      event.stopPropagation();
      // 更新clickedItems对象
      this.clickedItems = {};
      this.clickedItems[v] = true;
      // 设置当前页码
      this.currentPage = v;
      this.$emit('update-current-page', this.currentPage);
      this.$emit('update-current-navstatus', 'false');
      // 定义页面路由映射，用于将页码转换为对应的路由路径
      const routes = {
        1: '/',
        2: '/introduction',
        3: '/information',
        4: '/about'
      };

      // 根据页码跳转到对应的页面
      this.$router.push(routes[v]);
    },
    godetails(v){
      let p=''
      if (v.link!==null){
        p=v.link
      }else{
        p=''
      }
      // this.$router.push('/introduction/hard/buy')
      this.$router.push({path: '/introduction/hard/buy', query: { id: this.yid,pid: p}})
    }
  },
  mounted() {
    let hardswiper=new Swiper('#swiperthree', {
      slidesPerView: 1,
      autoplay:true,
      loop: true,
    })
    document.getElementById('radiosnav').style.display = 'none';
    gettab(0).then(res => {
      console.log(res);

      let compdata= res.data.find(item => item.moduleName === "硬件列表") || {};

      this.hardlistbanner=compdata.banners
      console.log('2222', this.hardlistbanner);
      this.yid=compdata.id
      this.introDataINfo1=compdata.infos

      this.hoverStates = new Array(this.introDataINfo1.length).fill(false);
      console.log(this.hoverStates);
      // this.introDataINfo2=compdata.infos[1]
    })

  }
}
</script>
<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 黑色半透明背景 */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* 默认透明 */
  transition: opacity 1s ease; /* 添加过渡效果 */
  cursor: pointer;
  border-radius: 14px;
}

.overlay.show {
  opacity: 1; /* 悬停时显示 */
}
.overlay.show2 {
  opacity: 1; /* 悬停时显示 */
}

.overlay-text {
  color: white;
  font-size: 20px;
}
.topbanner{
  height: 300px;
}
.topbanner img{
  height: 300px;
}
.app{
  width: 140px;
  height: 49px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 35px;
  color: #FFFFFF;
  line-height: 49px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 104px;
  left: 460px;
}
.app_int{

  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  left: 460px;
  bottom: 104px;
  position: absolute;
}
.ltv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, #FFE9EC 0%, rgba(255, 233, 236, 0.5) 100%);
  opacity: 0.8;
  position: relative;
}

.ltvtitle {
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 26px;
  right: 733px;
}

.ltvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  right: 131px;
  bottom: 27px;
  position: absolute;
}

.rtv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, rgba(255, 242, 205, 0.5) 0%, #FFF2CD 100%);;
  opacity: 0.8;
  position: relative;
}

.rtvtitle {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 42px;
  left: 100px
}

.rtvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  left: 100px;
  top: 77px;
  position: absolute;
}
</style>