

<template>
<div style="background-color: #F8F8F8;padding-bottom: 60px">
  <div class="nav_menu info" style="width: 100%">
    <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;margin-left: 84px">
      <img
          class="label_1 "
          referrerpolicy="no-referrer"
          src="../assets/img/wowpic.png"
      />
      <span class="text_1 footerwowtext">哇呜说</span>
    </div>
    <div style="margin-right: -30px;margin-left: 142px" class="menu_item sy" :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event)">
      <div class="home" style="width: 38px;">首页</div>
      <div class="web_home" style="width: 38px;">首页</div>
    </div>

    <div style="margin-left: 132px" id="xx"  class="menu_item"  :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }" >
      <div class="home">产品介绍</div>
      <div class="web_home"  @click.stop="page(2,$event)">产品介绍</div>
    </div>

    <div style="margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }" @click="page(3,$event)" @mouseover="" @animationend="">
      <div class="home">相关资讯</div>
      <div class="web_home">相关资讯</div>
    </div>

    <div style="margin-right: 30px;margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event)" @mouseover="" @animationend="">
      <div class="home">关于我们</div>
      <div class="web_home">关于我们</div>
    </div>
  </div>
  <div style="height: 60px;margin-top: 20px" class="nav_top">
    <div style="width: 1200px;height: 60px;margin: 0 auto">
      <span>您的位置:</span>&nbsp;&nbsp;
      <span @click="$router.push('/information')" style="cursor: pointer">资讯列表></span>&nbsp;&nbsp;
      <span style="color: #F8AE01">资讯详情</span>&nbsp;&nbsp;
    </div>

  </div>
  <div style="width: 1000px;margin: 0 auto">
      <P CLASS="article_title">{{ articleTitle }}</P>
    <p class="article_time">发表日期：{{ articleDate }}</p>

    <div id="article_content">

    </div>
  </div>
</div>
</template>
<script>
import {getnewsDetails} from "@/api/user";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      currentPage: 3,
      clickedItems: {},
      isRotated: false,
      articleContent:``,
      articleTitle:'',
      articleDate:''
    }
  },
  mounted() {
    getnewsDetails(this.$route.query.id).then(res=>{
      this.articleContent = res.data.content;
      document.getElementById('article_content').innerHTML = this.articleContent;
      this.articleTitle=res.data.intro
      this.articleDate=res.data.createTime
    })
   document.getElementsByClassName('nav_menu')[0].style.display = 'none';
  },
  beforeDestroy(){
    document.getElementsByClassName('nav_menu')[0].style.display = 'flex';
  },
  methods: {
    page(v,event) {
      if (!this.clickedItems[v]) {
        this.$nextTick(() => {

        });
      }
      console.log(event);
      event.stopPropagation();
      this.clickedItems = {};
      this.clickedItems[v] = true;
      this.currentPage = v;
      this.$emit('update-current-page', this.currentPage);
      this.$emit('update-current-navstatus', 'false');
      const routes = {
        1: '/',
        2: '/introduction',
        3: '/information',
        4: '/about'
      };

      // 根据页码跳转到对应的页面
      this.$router.push(routes[v]);
    },
  },

}
</script>
<style scoped>
.nav_menu.info{
  border-radius: unset;
  justify-content: unset;
  align-items: unset;
  /* align-items: center; */
  top: 0;
 margin-left: unset;
  position: unset;
}
.article_title{

  height: 28px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  margin-top: 40px;
}
.article_time{
  height: 21px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #999999;
  line-height: 21px;
  text-align: center;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}
#article_content img{
  width: 100% !important;
}
#article_content p{
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>