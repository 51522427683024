import request from './index';
//预备接口

// export function login(data) {
//     return request({
//         url: '/login',
//         method: 'post',
//         data
//     });
// }
//
export function getInfo() {
    return request({
        url: '/web/info',
        method: 'get'
    });
}

// 127.0.0.1:20200/web/news/page?page=1&pageSize=10&type=
export function getInfomation(page,pagesize,type){
    return request({
        url: `/web/news/page?page=${page}&pageSize=${pagesize}&type=${type}`,
        method: 'get',

    })
}
export function gettab(tab){
    return request({
        url: `/web/common?pid=${tab}`,
        method: 'get',

    })
}
export function getnewsDetails(tab){
    return request({
        url: `/web/news/get?id=${tab}`,
        method: 'get',

    })
}
