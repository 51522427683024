
<template>
  <div style="background-color: #F8F8F8">
    <div class="nav_menu info" style="width: 100%">
      <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;margin-left: 84px">
        <img
            class="label_1 "
            referrerpolicy="no-referrer"
            src="../assets/img/wowpic.png"
        />
        <span class="text_1 footerwowtext">哇呜说</span>
      </div>
      <div style="margin-right: -30px;margin-left: 142px" class="menu_item sy" :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event)">
        <div class="home" style="width: 38px;">首页</div>
        <div class="web_home" style="width: 38px;">首页</div>
      </div>

      <div style="margin-left: 132px" id="xx"  class="menu_item"  :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }" >
        <div class="home">产品介绍</div>
        <div class="web_home"  @click.stop="page(2,$event)">产品介绍</div>
      </div>

      <div style="margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }" @click="page(3,$event)" @mouseover="" @animationend="">
        <div class="home">相关资讯</div>
        <div class="web_home">相关资讯</div>
      </div>

      <div style="margin-right: 30px;margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event)" @mouseover="" @animationend="">
        <div class="home">关于我们</div>
        <div class="web_home">关于我们</div>
      </div>
    </div>

    <div class="topbanner">
<!--        <p class="app">-->
<!--          APP介绍-->
<!--        </p>-->
<!--      <p class="app_int">-->
<!--        这里展示对APP的介绍-->
<!--      </p>-->
<!--      <img src="../assets/img/xin.png" style="width: 70px;height: 70px;left: 907px;top: 59px" alt="">-->
<!--      <img src="../assets/img/2sj.png" style="width: 295px;height: 283px;right: 636px;top: 30px" alt="">-->
<!--      <img src="../assets/img/note.png" style="width: 58px;height: 58px;right: 537px;bottom: 20px" alt="">-->
      <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
        <div class="swiper-wrapper" style="z-index: 0">
          <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in applistbanner" :key="index">
            <img alt="" :src="item.img" style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px;margin-top: 19px" class="nav_top">
      <div style="width: 1200px;height: 60px;margin: 0 auto">
        <span>您的位置:</span>&nbsp;&nbsp;
        <span @click="$router.push('/introduction')" style="cursor: pointer">产品介绍></span>&nbsp;&nbsp;
        <span style="color: #F8AE01">APP介绍</span>&nbsp;&nbsp;
      </div>

    </div>
<div style="width:1200px;margin: 0 auto">
  <div style="display: flex;
    flex-direction: column;">
    <img v-for="(item,index) in extractedImages" :src="item" alt="">
  </div>


<!--    &lt;!&ndash;        左边电视&ndash;&gt;-->
<!--    <div style="width: 100%;display: flex;margin-top: 90px;align-items: baseline;">-->
<!--      <div style="">-->
<!--        <img style="width: 356px;height: 263px;" src="../assets/img/tv1.png" alt="">-->
<!--      </div>-->
<!--      <div class="ltv" style="">-->
<!--        <p class="ltvtitle" style="">创作剧本</p>-->
<!--        <div style="width: 74px;-->
<!--height: 7px;-->
<!--background: #FBB6B8;-->
<!--border-radius: 4px;position: absolute;top: 42px;right: 732px;z-index: -1"></div>-->
<!--        <div class="ltvp" style="">-->
<!--          <p>沉浸式剧本创作，直观的操作界面，降低您剧本创作的门槛。</p>-->
<!--          <p>无论您有无写作经验，都能通过该功能轻松创作出精彩的剧本。</p>-->
<!--          <p>家长可根据自家宝贝喜好，结合宝贝的日常见闻，天真童趣的想法，发挥孩子主观能动性，</p>-->
<!--          <p>生成专属于宝贝的个性化剧本！</p>-->
<!--          <p>同时，大数据工具帮您润色，海量的音色库为您加持！使剧本角色鲜活立体，剧本生动有趣。</p>-->
<!--          <p>剧本里的每个角色都由宝贝心爱的"小伙伴"来扮演，即使是同样的故事也能演绎出别样的趣味！</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;        右边电视&ndash;&gt;-->
<!--    <div style="width: 100%;display: flex;margin-top: 90px;align-items: baseline;">-->
<!--      <div class="rtv">-->
<!--        <p class="rtvtitle">哇呜代写</p>-->
<!--        <div style="width: 74px;-->
<!--height: 7px;-->
<!--background: #F7C982;-->
<!--border-radius: 4px;position: absolute;top: 57px;left: 99px;z-index: -1"></div>-->
<!--        <div class="rtvp">-->
<!--          <p>灵感枯竭？毫无思路？小问题，哇呜代写帮您搞定！</p>-->
<!--          <p>深度学习技术的自动化写作工具，通过分析大量的文本数据和经验知识，</p>-->
<!--          <p>自动生成符合您特定的主题和情节要求的剧本内容。</p>-->
<!--          <p>大数据指导内容生产，帮你搞定一切，轻轻松松展现才高八斗的你！</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="width: 347px;height: 220px;"><img style="width: 356px;height: 263px;" src="../assets/img/tv2.png"-->
<!--                                                    alt=""></div>-->
<!--    </div>-->
<!--    &lt;!&ndash;        左边电视&ndash;&gt;-->
<!--    <div style="width: 100%;display: flex;margin-top: 90px;align-items: baseline;">-->
<!--      <div style="width: 347px;height: 220px;"><img style="width: 356px;height: 263px;" src="../assets/img/tv3.png"-->
<!--                                                    alt=""></div>-->
<!--      <div class="ltv" style="">-->
<!--        <p class="ltvtitle" style="right: 715px">海量音色库</p>-->
<!--        <div style="width: 90px;-->
<!--height: 7px;-->
<!--background: #FBB6B8;-->
<!--border-radius: 4px;position: absolute;top: 42px;right: 715px;z-index: -1"></div>-->
<!--        <div class="ltvp" style="right: 90px">-->
<!--          <p>哇呜音色经过精心录制和后期制作，确保音质的高保真度和自然度，为您的精彩剧本增添趣味。</p>-->
<!--          <p>持续更新的海量音色库，紧跟创作者无限创意步伐，为每位"智能玩伴"量身打造独一无二的音色魅力。</p>-->
<!--          <p>情绪饱满的设定，让每句话都触动童心，成为孩子心中最温暖的陪伴。</p>-->
<!--          <p>"曾经有一个能说会道的小伙伴摆在我面前，我没有珍惜，等到失去才追悔莫及，</p>-->
<!--          <p>如果上天能给我重来一次的机会，我想说，这样的小伙伴，请给我来十个"</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;        右边电视&ndash;&gt;-->
<!--    <div style="width: 100%;display: flex;margin-top: 90px;align-items: baseline;margin-bottom: 60px">-->
<!--      <div class="rtv">-->
<!--        <p class="rtvtitle">亲子实时对话</p>-->
<!--        <div style="width: 109px;-->
<!--height: 7px;-->
<!--background: #F7C982;-->
<!--border-radius: 4px;position: absolute;top: 57px;left: 99px;z-index: -1"></div>-->
<!--        <div class="rtvp">-->
<!--          <p>灵感枯竭？毫无思路？小问题，哇呜代写帮您搞定！</p>-->
<!--          <p>深度学习技术的自动化写作工具，通过分析大量的文本数据和经验知识，</p>-->
<!--          <p>自动生成符合您特定的主题和情节要求的剧本内容。</p>-->
<!--          <p>大数据指导内容生产，帮你搞定一切，轻轻松松展现才高八斗的你！</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="width: 347px;height: 220px;"><img style="width: 356px;height: 263px;" src="../assets/img/tv4.png"-->
<!--                                                    alt=""></div>-->
<!--    </div>-->
<!--  &lt;!&ndash;        左边电视&ndash;&gt;-->
<!--  <div style="width: 100%;display: flex;margin-top: 90px;align-items: baseline;">-->
<!--    <div style="width: 347px;height: 220px;"><img style="width: 356px;height: 263px;" src="../assets/img/robot.png"-->
<!--                                                  alt=""></div>-->
<!--    <div class="ltv" style="">-->
<!--      <p class="ltvtitle" style="right: 736px">哇呜托管</p>-->
<!--      <div style="width: 75px;-->
<!--height: 7px;-->
<!--background: #FBB6B8;-->
<!--border-radius: 4px;position: absolute;top: 42px;right: 733px;z-index: -1"></div>-->
<!--      <div class="ltvp" style="right: 90px">-->
<!--        <p>在忙碌的生活中仍渴望陪伴孩子的温馨时光？瞧，哇呜小助手华丽亮相，成为您不可或缺的亲密伙伴！</p>-->
<!--        <p>只需点击【哇呜托管】，并设置您想要哇呜与宝贝共度的趣味方向，即刻释放您的双手，</p>-->
<!--        <p>让亲子间的每一刻都充满</p>-->
<!--        <p>欢笑与温馨。哇呜小助手，让爱的陪伴跨越界限，无拘无束！</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
    <!--        //-->
</div>
    <div style="height: 60px;width: 100%"></div>
  </div>
</template>
<script>
import {gettab} from "@/api/user";
import Swiper from "swiper";

export default {
  name: "int_app",
  data() {
    return {
      currentPage: 2,
      clickedItems: {},
      isRotated: false,
      articleContent:``,
      introData_banner:'',
      introDataINfo1:'',
      extractedImages: [],
      applistbanner: []
    }
  },
  methods:{
    page(v,event) {
      if (!this.clickedItems[v]) {
        this.$nextTick(() => {

        });
      }
      // document.getElementById('xx').style.fontWeight = 'unset !important';
      // this.navanimate(1,3)
      console.log(event);
      event.stopPropagation();
      // 更新clickedItems对象
      this.clickedItems = {};
      this.clickedItems[v] = true;
      // 设置当前页码
      this.currentPage = v;
      this.$emit('update-current-page', this.currentPage);
      this.$emit('update-current-navstatus', 'false');
      // 定义页面路由映射，用于将页码转换为对应的路由路径
      const routes = {
        1: '/',
        2: '/introduction',
        3: '/information',
        4: '/about'
      };

      // 根据页码跳转到对应的页面
      this.$router.push(routes[v]);
    },
  },
  mounted() {
    // document.getElementById('radiosnav').style.display = 'none';
    gettab(0).then(res => {
      console.log(res);
      let compdata= res.data.find(item => item.moduleName === "软件列表") || {};


      console.log(compdata);
      this.extractedImages = compdata.infos.flatMap(item =>
          item.imgs.map(img => img.img)
      );
      console.log(this.extractedImages); // 输出提取的图片链接
      this.applistbanner=compdata.banners
      console.log('sbsbsbsbsb',compdata.banners);
      // let a=compdata.infos.flatMap(item =>
      //     item.imgs.map(img => img.img)
      // );
      // this.introDataINfo1=a
      // this.introDataINfo2=compdata.infos[1]
    })
    let hardswiper=new Swiper('#swiperthree', {
      slidesPerView: 1,
      autoplay:true,
      loop: true,
      observer: true,
      observeParents: true,
    })
  }
}
</script>
<style scoped>
.topbanner{
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.app{
  width: 140px;
  height: 49px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 35px;
  color: #FFFFFF;
  line-height: 49px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 104px;
  left: 460px;
}
.app_int{

  width: 239px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  left: 460px;
  bottom: 104px;
  position: absolute;
}
.ltv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, #FFE9EC 0%, rgba(255, 233, 236, 0.5) 100%);
  opacity: 0.8;
  position: relative;
}

.ltvtitle {
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;

  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 26px;
  right: 733px;
}

.ltvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  right: 131px;
  bottom: 27px;
  position: absolute;
}

.rtv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, rgba(255, 242, 205, 0.5) 0%, #FFF2CD 100%);;
  opacity: 0.8;
  position: relative;
}

.rtvtitle {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 42px;
  left: 100px
}

.rtvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  left: 100px;
  top: 77px;
  position: absolute;
}
</style>