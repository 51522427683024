import axios from 'axios';
// baseURL: process.env.VUE_APP_BASE_API, // 设置默认的基础URL
// 创建axios实例
const service = axios.create({

    baseURL: process.env.VUE_APP_API_BASEURL,
    // 设置默认的基础URL


    timeout: 5000 // 设置超时时间
});

// 请求拦截器
service.interceptors.request.use(config => {
    // 可以在此添加如token等头部信息
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return config;
}, error => {
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        // 根据后端返回的状态码进行不同的处理
        if (res.code !== 200) {
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            return res;
        }
    },
    error => {
        console.log('err' + error); // for debug
        return Promise.reject(error);
    }
);

export default service;
