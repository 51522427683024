<template>
<div>
  <div class="nav_menu info" style="width: 100%">
    <div @click="page(1)" class="cursor" style="display: flex;align-items: center;    margin-left: 26px;
    margin-right: -37px;margin-left: 84px">
      <img
          class="label_1 "
          referrerpolicy="no-referrer"
          src="../assets/img/wowpic.png"
      />
      <span class="text_1 footerwowtext">哇呜说</span>
    </div>
    <div style="margin-right: -30px;margin-left: 142px" class="menu_item sy" :class="{ 'activets': currentPage === 1, 'clicked': clickedItems[1] }" @click="page(1,$event)">
      <div class="home" style="width: 38px;">首页</div>
      <div class="web_home" style="width: 38px;">首页</div>
    </div>

    <div style="margin-left: 132px" id="xx"  class="menu_item"  :class="{ 'activenav': currentPage === 2 , 'rotate': isRotated  }" >
      <div class="home">产品介绍</div>
      <div class="web_home"  @click.stop="page(2,$event)">产品介绍</div>
    </div>

    <div style="margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 3, 'clicked': clickedItems[3] }" @click="page(3,$event)" @mouseover="" @animationend="">
      <div class="home">相关资讯</div>
      <div class="web_home">相关资讯</div>
    </div>

    <div style="margin-right: 30px;margin-left: 132px" class="menu_item" :class="{ 'activenav': currentPage === 4, 'clicked': clickedItems[4] }" @click="page(4,$event)" @mouseover="" @animationend="">
      <div class="home">关于我们</div>
      <div class="web_home">关于我们</div>
    </div>
  </div>
  <div style="height: 60px" class="nav_top">
    <div style="width: 1200px;height: 60px;margin: 0 auto">
      <span>您的位置:</span>&nbsp;&nbsp;
      <span @click="$router.push('/introduction/hard')" style="cursor: pointer">产品介绍></span>&nbsp;&nbsp;
      <span style="color: #F8AE01">产品细节</span>&nbsp;&nbsp;
    </div>

  </div>
  <div class="swiper-container" id="swiperone">
    <div class="swiper-wrapper">

      <div @click="buy()" class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in hardlistbanner" :key="index">
        <img alt="" :src="item.img" style="width: 100%;">
      </div>
    </div>
    <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
    <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
    <div class="swiper-pagination"></div><!--分页器。如果放置在swiper外面，需要自定义样式。-->
  </div>

  <img v-for="(item,index) in introDataINfo1" :src="item.imgs[index].img" alt="" style="width: 100%;margin-top: 153px;margin-bottom: 110px">

<!--  <div class="swiper-container" id="swipertwo">-->
<!--    <div class="swiper-wrapper">-->
<!--      <div class="swiper-slide swiper-slide1">-->
<!--        <img src="../assets/img/buy_infoswiper.png" style=" width: 1920px;-->
<!--  height: 967px;" alt="">-->
<!--      </div>-->
<!--      <div class="swiper-slide swiper-slide2">-->
<!--        <img src="../assets/img/buy_infoswiper.png" style=" width: 1920px;-->
<!--  height: 967px;" alt="">-->
<!--      </div>-->
<!--      <div class="swiper-slide swiper-slide3">-->
<!--        <img src="../assets/img/buy_infoswiper.png" style=" width: 1920px;-->
<!--  height: 967px;" alt="">-->
<!--      </div>-->
<!--      <div class="swiper-slide swiper-slide4">-->
<!--        < <img src="../assets/img/buy_infoswiper.png" style=" width: 1920px;-->
<!--  height: 967px;" alt="">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="swiper-button-prev"></div>&lt;!&ndash;左箭头。如果放置在swiper外面，需要自定义样式。&ndash;&gt;-->
<!--    <div class="swiper-button-next"></div>&lt;!&ndash;右箭头。如果放置在swiper外面，需要自定义样式。&ndash;&gt;-->
<!--    <div class="swiper-pagination"></div>&lt;!&ndash;分页器。如果放置在swiper外面，需要自定义样式。&ndash;&gt;-->
<!--  </div>-->

</div>
</template>


<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'
import {gettab} from "@/api/user";
export default {
  name: "hardbuy",
  data() {
    return {
      currentPage: 2,
      clickedItems: {},
      isRotated: false,
      articleContent:``,
      hardlistbanner:'',
      link:''
    }
  },
  methods:{
    page(v,event) {
      if (!this.clickedItems[v]) {
        this.$nextTick(() => {

        });
      }
      // document.getElementById('xx').style.fontWeight = 'unset !important';
      // this.navanimate(1,3)
      console.log(event);
      event.stopPropagation();
      // 更新clickedItems对象
      this.clickedItems = {};
      this.clickedItems[v] = true;
      // 设置当前页码
      this.currentPage = v;
      this.$emit('update-current-page', this.currentPage);
      this.$emit('update-current-navstatus', 'false');
      // 定义页面路由映射，用于将页码转换为对应的路由路径
      const routes = {
        1: '/',
        2: '/introduction',
        3: '/information',
        4: '/about'
      };

      // 根据页码跳转到对应的页面
      this.$router.push(routes[v]);
    },
    buy(){
      window.open(this.link, '_self');

    }
  },
  mounted() {
    document.getElementById('radiosnav').style.display = 'none';

    var swiper = new Swiper('#swiperone', {
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      loop: true,
      observer: true,
      observerParents: true,
    });
    var swiper_butdetails = new Swiper('#swipertwo', {
      pagination: {
        el: '.swiper-pagination',
      },
      // navigation: {
      //   nextEl: '.swiper-button-next1',
      //   prevEl: '.swiper-button-prev1',
      // },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      loop: true,
    });
    gettab(this.$route.query.id).then(res => {
      console.log('kk',res);

      let compdata= res.data.find(item => item.id === this.$route.query.pid) || {};
      if (compdata){
        this.hardlistbanner=compdata.banners
        this.introDataINfo1=compdata.infos
        this.link=compdata.infos[0].link
      }


      this.hoverStates = new Array(this.introDataINfo1.length).fill(false);
      console.log(this.hoverStates);
      // this.introDataINfo2=compdata.infos[1]
    })
  }
}
</script>
<style type="text/css">
.swiper-container{
  //--swiper-theme-color:  #FFFFFF;
  --swiper-pagination-color: #FFFFFF;/* 两种都可以 */
}
</style>
<style scoped>
.swiper-container{
  width: 100%;
  height: 872px;
  overflow: hidden;
}
.swiper-container_info{
  width: 1920px;
  height: 967px;
  overflow: hidden;
}
.swiper-button-next-banner{
  width: 56px;
  height: 56px;
  background: #333333;
  border: 2px solid #FFFFFF;
}
.swiper-button-prev-banner{
  width: 56px;
  height: 56px;
  background: #333333;
  border: 2px solid #FFFFFF;
}
.swiper-button-next, .swiper-button-prev{
  color: #FFFFFF;
}
.nav_top span{
  width: 162px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 60px;
  text-align: left;
  font-style: normal;
}

</style>
