

<template>
    <div style="background-color: #F8F8F8;">

      <div style="
height: 798px;position: relative">
        <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
          <div class="swiper-wrapper" style="z-index: 0">
            <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in aboutbanners" :key="index">
              <img alt="" :src="item.img" style="width: 100%;">
            </div>
          </div>
        </div>
<!--        <span class="newstext1">关于哇呜说</span>-->
<!--        <span class="newstext2">实时关注，实时更新，第一时间掌握哇呜推荐与新闻资讯 更快了解新闻动态 </span>-->
      </div>
      <div style="width: 1000px;text-align: center;margin: 0 auto">

        <div class="hd" style="" v-for="(item,index) in aboutinfo1" :key="index">

          <div class="hddiv1" style="margin-top: 60px">
            <img alt="" src="../assets/img/xiongzhua.png">
            <p class="hdp" style="width: unset !important;">{{ item.title }}</p>
            <div class="hdd" style=""></div>
          </div>
          <div class="lndesc">
          {{item.subtitle}}
          </div>
          <img v-for="(item1,index1) in item.imgs" class="about_img" style="" :src="item1.img" alt="">
        </div>
      </div>
    </div>
</template>
<script>
import {gettab} from "@/api/user";
import Swiper from "swiper";
export default {
  data() {
    return {
      aboutbanners:'',
      aboutinfo1:''
    }
  },
  methods: {

  },
  mounted() {
    gettab(0).then(res => {
      console.log(res);
      let compdata= res.data.find(item => item.moduleName === "关于我们") || {};
      this.aboutbanners=compdata.banners
      this.aboutinfo1=compdata.infos
      console.log('我是蔡徐坤',compdata);
    }),
        new Swiper('#swiperthree', {
          slidesPerView: 1,
          autoplay:true,
          loop: true,
        })
  }
}
</script>
<style scoped>
.newstext1 {

  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #FFFFFF;
  line-height: 84px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 307px;
  left: 363px;
}

.newstext2 {
  width: 750px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  position: absolute;
  bottom: 289px;
  left: 363px;
}
.hddescabout span{
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #5E3B15;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-top: 50px;
}
.xg{
  width: unset !important;display: flex;
  align-items: center;margin-left: 510px;
  margin-top: 24px;
}
.xg div{
  width: 61px;height: 1px;background-color:#F8AE01;margin-right: 14px
}
.xg span{
  width: 105px;
  height: 29px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 21px;
  color: #F8AE01;
  line-height: 29px;
  text-align: left;
  font-style: normal;margin-right: 14px;
}
.xg img{
  margin-right: 12px;width: 40px;height: 33px;margin-top: 15px
}
.lndesc{
  width: 1000px;
  height: 120px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #5E3B15;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-top: 50px;
}
.lndesc p{
  text-indent: 50px;
}
.about_img{
  width: 1000px;
  height: 400px;
  margin-top: 40px;
  margin-bottom: 60px;
}
</style>