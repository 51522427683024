import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import home from "/src/components/home.vue";
import introduction from "/src/components/Introduction.vue";
import information from "/src/components/information.vue";
import about from "/src/components/about.vue";
import infodetails from "/src/components/infoDetail.vue";
import appdetails from "/src/components/int_app.vue";
import harddetails from "/src/components/harddetails.vue";
import hardbuy from "/src/components/hardbuy.vue";

// 使用 Router
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: "/",
            component: home,
            name: "home"
        },
        {
            path: "/introduction",
            component: introduction,
            name: "introduction"
        },
        {
            path: "/information",
            component: information,
            name: "information"
        },
        {
            path: "/information/infodetails",
            component: infodetails,
            name: "infodetails"
        },
        {
            path: "/introduction/app",
            component: appdetails,
            name: "appdetails"
        },
        {
            path: "/introduction/hard",
            component: harddetails,
            name: "harddetails"
        },
        {
            path: "/introduction/hard/buy",
            component: hardbuy,
            name: "hardbuy"
        },
        {
            path: "/about",
            component: about,
            name: "about"
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // 每次路由跳转时滚动到顶部
        return { x: 0, y: 0 };
    }
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

export default router;
