<template>
  <div>
    <!--背景图-->
    <div style="
height: 798px;
background: #F8AE01;position: relative">
      <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
        <div class="swiper-wrapper" style="z-index: 0">
          <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in introData_banner" :key="index">
            <img alt="" :src="item.img" style="width: 100%;">
          </div>
        </div>
      </div>
<!--      <span class="inttext1">产品介绍</span>-->
<!--      <span class="inttext2">涵盖了软件与硬件的相结合，打造出一款更适用于儿童的APP </span>-->
<!--      <span class="inttext3">亲子共创，梦幻剧本舞台携手智能玩伴，线上线下无缝融合，-->
<!--打造孩子专属的沉浸式互动成长奇境，让陪伴与创意同频共振！</span>-->
<!--      <img src="../assets/img/tighersit.png"-->
<!--           style="width: 358px;height: 448px;position: absolute;top: 393px;right: 71px" alt="">-->
    </div>
    <!--    //-->

    <!--    产品软件硬件介绍-->
    <div style="background-color: #F8F8F8;">
      <div style="width: 1000px;margin: 0 auto;padding-top: 63px" v-for="(item,index) in introDataINfo1">
        <!--        产品模式-->
        <div>
          <div class="hd" style="">
            <div class="hddiv1">
              <img alt="" src="../assets/img/xiongzhua.png">
              <p class="hdp">{{ item.title }}</p>
              <div class="hdd"></div>
            </div>
            <p class="hddesc" style="width:unset">{{ item.subtitle }}</p>
          </div>
        </div>

        <!--        //-->
        <div class="cpms" style="" :class="{ catelist1: index === 1 }" >
          <img @click="goNext(index,index1)" v-for="(item1,index1) in item.imgs" class="cpmscontain" :class="{ catelist: index === 1 }" style="" :src="item1.img" alt="">
<!--          <img class="cpmscontain" style="" :src="item.img" alt="">-->
<!--          </img>-->
<!--          <div class="">-->
<!--            <p class="cpmstitle" style="">科技创作平台+独创智能硬件</p>-->
<!--            <p class="cpmstext" style="">亲子共创，梦幻剧本舞台携手智能玩伴，线上线下无缝融合，-->
<!--              打造孩子专属的沉浸式互动成长奇境，让陪伴与创意同频共振！</p>-->
<!--          </div>-->
        </div>
        <!--        智慧赋能-->
<!--        <div style="margin-top: 64px">-->
<!--          <div class="hd" style="">-->
<!--            <div class="hddiv1">-->
<!--              <img alt="" src="../assets/img/xiongzhua.png">-->
<!--              <p class="hdp">{{ introDataINfo2.title }}</p>-->
<!--              <div class="hdd"></div>-->
<!--            </div>-->
<!--            <p class="hddesc" style="width:unset">-->
<!--              {{ introDataINfo2.subtitle }}</p>-->
<!--          </div>-->

<!--        </div>-->
<!--        <div style="display: flex;justify-content: space-between;margin-bottom: 120px">-->
<!--          <div  @mouseenter="activateApp" @mouseleave="deactivate" class="tabshow" :style="{ background: isActive === 'app' ? '#F8AE01' : 'white', color: isActive === 'app' ? 'white' : '#333333'}"  @click="toggleApp">-->
<!--            <span class="tstext">APP</span>-->
<!--&lt;!&ndash;            <span class="tsdesc">这一段文字是针对app的介绍 写两行</span>&ndash;&gt;-->
<!--            <img src="../assets/img/iPhone.png" style="width: 135px;height: 275px;right: 46px;top: 0" alt="">-->
<!--            <img src="../assets/img/xin.png" style="width: 65px;height: 63px;left: 199px;top: 15px" alt="">-->
<!--            <img src="../assets/img/tigerwo.png" style="width: 179px;height: 99px;top: -87px;right: -43px;" alt="">-->
<!--          </div>-->
<!--          <div @mouseleave="deactivate"  @mouseenter="activateDevice"-->
<!--                 class="tabshow" :style="{ background: isActive === 'device' ? '#F8AE01' : 'white', color: isActive === 'device' ? 'white' : '#333333' }"  @click="toggleDevice(2)">-->
<!--            <span class="tstext">硬件</span>-->
<!--&lt;!&ndash;            <span class="tsdesc">这一段文字是针对设备的介绍 写两行</span>&ndash;&gt;-->
<!--            <img src="../assets/img/music.png" style="width: 64px;height: 57px;left: 151px;bottom: 154px" alt="">-->
<!--            <img src="../assets/img/yinfu.png" style="width: 54px;height: 66px;right: 10px;bottom: 187px" alt="">-->
<!--            <img src="../assets/img/yinxiang.png" style="width: 287px;height: 186px;right: 10px;bottom: 8px" alt="">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="margin-top: 59px;width: 100%;height: 1px"></div>-->
      </div>
    </div>
  </div>
</template>
<script>

import {gettab} from "@/api/user";
import Swiper from "swiper";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      isActive: true,
      introData_banner:'',
      introDataINfo1:'',
      introDataINfo2:''
    }
  },
  methods: {
    goNext(k,index){
      console.log(k,index);
      if (k===1&&index===0){
        this.toggleApp()
      }
      if (k===1&&index===1){
        this.toggleDevice()
      }
      // this.$router.push('/introduction/hard')
    },
    toggleApp() {
      this.$router.push('/introduction/app')
      // 只有当点击的是非活动状态的时候才切换状态
      if (!this.isActive) {
        this.isActive = true;
      }
    },
    toggleDevice() {
      this.$router.push('/introduction/hard')
      // 只有当点击的是活动状态的时候才切换状态
      if (this.isActive) {
        this.isActive = false;
      }
    },
    activateApp() {
      this.isActive = 'app';
      // this.$router.push('/introduction/app');
    },
    activateDevice() {
      this.isActive = 'device';
      // this.$router.push('/introduction/hard');
    },
    deactivate() {
      this.isActive = null;  // 设置为null或未定义表示没有活动状态
    }
  },
  mounted() {
    gettab(0).then(res => {
      console.log(res);

       let compdata= res.data.find(item => item.moduleName === "产品介绍") || {};

      this.introData_banner=compdata.banners
      this.introDataINfo1=compdata.infos
      // this.introDataINfo2=compdata.infos[1]
    })
    var swiperInstance2 = new Swiper('#swiperthree', {
      // config...
      // autoplay:true,
      slidesPerView: 1,
      autoplay:true,
      loop: true,
      observer: true,
      observeParents: true,
    })
  }
}


</script>
<style scoped>
body {
  background-color: #F8F8F8;
}

.inttext1 {
  width: 240px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #FFFFFF;
  line-height: 84px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 280px;
  left: 363px;
}

.inttext2 {
  width: 819px;
  height: 42px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 388px;
  left: 363px;
}

.inttext3 {
  width: 672px;
  height: 198px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 461px;
  left: 363px;
}

.tabshow {
  width: 470px;
  height: 220px;
  //background: #F8AE01;
  box-shadow: 0px 6px 14px 0px rgba(248, 174, 1, 0.53);
  border-radius: 10px;
  position: relative;
  margin-top: 160px;
  cursor: pointer;
}

.tabshow * {
  position: absolute;
}

.tabshow .tstext {
  width: 60px;
  height: 42px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  //color: #FFFFFF;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  top: 89px;
  left: 69px;
}

.tsdesc {
  width: 233px;
  height: 50px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  //color: #FFFFFF;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  bottom: 67px;
  left: 22px;
}

.adv {
  width: 200px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 10px;
  //border: 1px solid #E2E2E2;
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.advtext {
  width: 112px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.advbody {
  display: flex;
  justify-content: space-between;
}

.scriptbody {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.scriptbodydiv {
  width: 320px;
  height: 331px;
}

.scriptbodydiv img {
  width: 320px;
  height: 331px
}

.scripttitle {
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  margin-top: 42px;
}

.scriptdesc {
  width: 640px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 14px;
}

.cpms {;
  width: 838px;
  height: 221px;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cpmscontain {
  width: 100%;
  //width: 360px;
  height: 221px;
}
.catelist{
  width: 510px;
  height: 330px;
}
.catelist1{
  padding-bottom: 60px;
  margin-top: 85px;
}

.cpms img{

}
.cpmstitle {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}

.cpmstext {
  width: 448px;
  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 14px
}

.ltv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, #FFE9EC 0%, rgba(255, 233, 236, 0.5) 100%);
  opacity: 0.8;
  position: relative;
}

.ltvtitle {
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 26px;
  right: 733px;
}

.ltvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  right: 131px;
  bottom: 27px;
  position: absolute;
}

.rtv {
  width: 927px;
  height: 220px;
  background: linear-gradient(270deg, rgba(255, 242, 205, 0.5) 0%, #FFF2CD 100%);;
  opacity: 0.8;
  position: relative;
}

.rtvtitle {

  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 42px;
  left: 100px
}

.rtvp {

  height: 132px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  left: 100px;
  top: 77px;
  position: absolute;
}
</style>