<template>
  <div style=" background-color: #F8F8F8;">
    <div style="
height: 798px;position: relative">

      <div class="swiper-container" id="swiperthree" style="width: 100%;z-index: 1;margin-top: unset">
        <div class="swiper-wrapper" style="z-index: 0">
          <div class="swiper-slide" style="width: 100%" :class="`swiper-slide${index + 1}`"  v-for="(item,index) in infobanners" :key="index">
            <img alt="" :src="item.img" style="width: 100%;">
          </div>
        </div>
      </div>

<!--      <span class="newstext1">相关资讯</span>-->
<!--      <span class="newstext2">实时关注，实时更新，第一时间掌握哇呜推荐与新闻资讯更快了解新闻动态</span>-->
<!--      <img src="../assets/img/tv.png" style="width: 597px;height: 495px;position: absolute;top: 331px;right: 0px"-->
<!--           alt="">-->
    </div>
    <div>


      <div class="newscate">
        <div class="newscateblock" :style="{ background: isActive === 0 ? '#F8AE01' : 'white', color: isActive === 0 ? 'white' : '#333333' }" @click="isActive = 0">
          <img src="../assets/img/maopao.png" alt="">
          <span>全部</span>
        </div>
        <div class="newscateblock" :style="{ background: isActive === 1 ? '#F8AE01' : 'white', color: isActive === 1 ? 'white' : '#333333' }" @click="isActive = 1">
          <img src="../assets/img/tigersmall.png" alt="">
          <span style="width: 72px">哇呜推荐</span>
        </div>

        <div class="newscateblock" :style="{ background: isActive === 2 ? '#F8AE01' : 'white', color: isActive === 2 ? 'white' : '#333333' }" @click="isActive = 2">
          <img src="../assets/img/news.png" alt="">
          <span style="width: 72px">新闻资讯</span>
        </div>
      </div>


<!--      资讯列表-->
      <div v-show="isActive === 0">
      <div v-for="(item,index) in allarticledata" :key="index" class="article"
           :class="{ 'article': true, 'last-article': isLastItem(index) }" @click="newsdetails(item)">
        <div style="width: 163px;height: 163px">
          <img class="articleimg" style="" :src="item.img" alt="">
        </div>
        <div style="width: 792px">
          <p class="articletitle">{{ item.name }}1</p>
          <p class="articledesc">{{ item.intro }}</p>
          <p class="articletime">{{ item.shelfTime }}</p>
        </div>
      </div>
        <div style="width: 100%;text-align: center;margin-bottom: 60px">
          <el-pagination style="margin: 0 auto;"

                         background=""
                         layout="prev, pager, next"
                         :total="all_total"
                         :current-page="all_currentpage"
                         :page-size="all_pagesize"
                         :current-page.sync="all_currentpage"
                         @current-change="clickpage()"
          >
          </el-pagination>
        </div>

      </div>

      <div v-show="isActive === 1">
        <div v-for="(item,index) in articledata1" :key="index" class="article"
             :class="{ 'article': true, 'last-article': isLastItem(index) }" @click="newsdetails(item)">
          <div style="width: 163px;height: 163px">
            <img class="articleimg" style="" :src="item.img" alt="">
          </div>
          <div style="width: 792px">
            <p class="articletitle">{{ item.name }}1</p>
            <p class="articledesc">{{ item.intro }}</p>
            <p class="articletime">{{ item.shelfTime }}</p>
          </div>
        </div>
        <div style="width: 100%;text-align: center;margin-bottom: 60px">
          <el-pagination style="margin: 0 auto;"
                         background=""
                         layout="prev, pager, next"
                         :total="total1"
                         :current-page="currentpage1"
                         :page-size="pagesize1"
                         :current-page.sync="currentpage1"
                         @current-change="clickpage(0)"
                         >
          </el-pagination>
        </div>
      </div>
      <div v-show="isActive === 2">
        <div v-for="(item,index) in articledata2" :key="index" class="article"
             :class="{ 'article': true, 'last-article': isLastItem(index) }" @click="newsdetails(item)">
          <div style="width: 163px;height: 163px">
            <img class="articleimg" style="" :src="item.img" alt="">
          </div>
          <div style="width: 792px">
            <p class="articletitle">{{ item.name }}</p>
            <p class="articledesc">{{ item.intro }}</p>
            <p class="articletime">{{ item.shelfTime }}</p>
          </div>
        </div>
        <div style="width: 100%;text-align: center;margin-bottom: 60px">
          <el-pagination style="margin: 0 auto;"
                         background=""
                         layout="prev, pager, next"
                         :total="total2"
                         :current-page="currentpage2"
                         :page-size="pagesize2"
                         :current-page.sync="currentpage2"
                         @current-change="clickpage(1)"
          >
          </el-pagination>
        </div>
      </div>

      <div style="width: 100%;height: 1px">

      </div>
    </div>
  </div>
</template>
<script>
import {getInfomation, gettab} from "@/api/user";
import Swiper from "swiper";

export default {
  name: 'App',
  components: {},

  data() {
    return {
      isActive: 0,

      all_currentpage: 1,
      all_pagesize: 5,
      all_total: 0,
      allarticledata:'',

      currentpage1: 1,
      pagesize1: 5,
      total1: 0,
      articledata1:'',

      currentpage2: 1,
      pagesize2: 5,
      total2: 0,
      articledata2:'',


      infobanners:''
    }
  },
  methods: {
    isLastItem(index) {
      return index === this.allarticledata.length - 1;
    },
    newsdetails(item) {
      this.$router.push({path: '/information/infodetails', query: { id: item.id }})
    },
    // prevPage(w){
    //   this.all_currentpage-=1
    // },
    // nextPage(c){
    //   this.all_currentpage+=1
    //   console.log(this.all_currentpage);
    // },
    clickpage(v){

      if (v==0){
        this.getnews(this.currentpage1,this.pagesize1,0)
        return
      }
      if (v==1){
        this.getnews(this.currentpage2,this.pagesize2,1)
        return;
      }
      if (!v){
        this.getnews(this.all_currentpage,this.all_pagesize,'')
        return;
      }
    },
    getnews(c,s,a) {
      getInfomation(c,s,a) .then(res => {
        switch (a) {
          case 0:
            this.articledata1 = res.data.rows;
            this.total1 = res.data.total;
            break
          case 1:
            this.articledata2 = res.data.rows;
            this.total2 = res.data.total;
            break
          default:
            this.allarticledata = res.data.rows;
            this.all_total = res.data.total;
            break
        }
      })
    },


  },
  mounted() {
    this.isActive = 0;
    this.getnews(1,5,'')
    this.getnews(1,5,0)
    this.getnews(1,5,1)
    gettab(0).then(res => {
      console.log(res);
      let compdata= res.data.find(item => item.moduleName === "相关资讯") || {};
    this.infobanners=compdata.banners
    })
    new Swiper('#swiperthree', {
      slidesPerView: 1,
      autoplay:true,
      loop: true,
      observer: true,
      observeParents: true,
    })
  }
}
</script>

<style scoped>
.articleimg {
  width: 163px;
  height: 163px;object-fit: cover;
}

.newstext1 {
  width: 240px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 60px;
  color: #FFFFFF;
  line-height: 84px;
  text-align: left;
  font-style: normal;
  position: absolute;
  top: 307px;
  left: 363px;
}

.newstext2 {
  width: 750px;
  height: 84px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 42px;
  text-align: left;
  font-style: normal;
  position: absolute;
  bottom: 289px;
  left: 363px;
}

.newscate {
  width: 1000px;

  margin: 60px auto 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.newscateblock {
  width: 300px;
  height: 80px;
  //background: #F8AE01;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F8AE01;
  cursor: pointer;
}

.newscateblock img {
  width: 37px;
  height: 36px;
  margin-right: 26px;
}

.newscateblock span {
  width: 36px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  //color: #FFFFFF;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}

.article {
  width: 1000px;
  height: 203px;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.articletitle {
  height: 28px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  overflow: hidden;
}

.articledesc {
  width: 768px;
  height: 72px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 12px;
}

.articletime {
  width: 238px;
  height: 21px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #999999;
  line-height: 21px;
  text-align: left;
  font-style: normal;
  margin-top: 10px;
}

.last-article {
  margin-bottom: 59px;
}
.article {
  transition: box-shadow 0.3s; /* Add a transition effect for smoother hover */
  cursor: pointer;
}

.article:hover {
  box-shadow: 0px 10px 11px 0px rgba(115,106,89,0.2)
}
.el-pagination__pager--current {
  background-color: #F8AE01;
}
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #F8AE01 !important;
}
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active:hover){
  color: white;
}

:deep(.el-pagination.is-background .el-pager li){
  background-color: #FFFFFF !important;
}
:deep(.el-pagination.is-background .el-pager li:not(.disabled):hover){
  color: #F8AE01;
}
</style>